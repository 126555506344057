// action - state management
import { LOADING_SPIN } from './actions';

export const initialState = {
    loading: false,
};

//-----------------------|| type reducer ||-----------------------//

const utilsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_SPIN: {
            const { loading } = action.payload;
            return {
                ...state,
                loading
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default utilsReducer;
