import { api } from "config/api.js";

class AuthServices {
  login = async (params) => {
    return api.post("/api/auth/login", { ...params });
  };
  changePassword = async (params) => {
    params.isSaveLog = true;
    return api.put("/api/auth/change-password", { ...params });
  };
  verifyToken = async () => {
    return api.get("/api/auth/verify-token");
  };
}

export const authServices = new AuthServices();
