import { Button } from "antd";
import Logo from "assets/img/logo_B.png";
import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ChangePasswordModal from "./ChangePasswordModal";

const Header = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("access_token");
    Cookies.remove("userId");
    Cookies.remove("roles");
    navigate("/login");
  };

  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="home-container p-2">
      <ChangePasswordModal isModalOpen={openModal} onCloseModal={closeModal} />
      <div className="topbar-container">
        <div className="ml-7 flex space-x-6 flex-row">
          <img
            src={Logo}
            alt="Logo"
            className="max-h-10	max-w-40 cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <div>
          <Button
            type="primary"
            style={{ marginRight: "8px" }}
            onClick={showModal}
          >
            パスワード変更
          </Button>
          <Button type="primary" onClick={() => handleLogout()}>
            ログアウト
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
