const HTTP_STATUS_CONTSTANTS = {
    SUCCESS: 200,
    CREATE_SUCCESS: 201,
    ERROR_CODE_400: 400,
    ERROR_CODE_401: 401,
    ERROR_CODE_404: 404,
    INTERNAL_SERVER_ERROR: 500,
    CONTRACT_FALSE: 32603,
    DEPOSIT_TRANSACTION_FALSE: 0,
};

export default HTTP_STATUS_CONTSTANTS;
