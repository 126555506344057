import { lazy } from "react";

const HomePage = lazy(() => import("./Homepage"))
const Login = lazy(() => import("./Login"))

let routes = [
    {
        path: "/login",
        exact: true,
        public: true,
        element: <Login />
    },
    {
        path: "/",
        exact: true,
        public: true,
        element: <HomePage />
    },
]

export default routes