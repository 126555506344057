import { Button, Form, Input, Modal, notification, message } from "antd";
import Cookies from "js-cookie";
import { authServices } from "services/AuthServices";
import MESSAGES from "config/constant/messages";
import { REGEX_PASSWORD } from "config/constant/regex";

const ChangePasswordModal = ({ isModalOpen, onCloseModal }) => {
  const [api, contextHolder] = notification.useNotification();
  const { changePassword } = authServices;
  const [form] = Form.useForm();
  const onFinish = (values) => {
    changePassword({ ...values, userId: Cookies.get("userId") })
      .then(() => {
        message.success(MESSAGES.MSG_I_008);
        handleCancel();
      })
      .catch((err) => {
        message.error(
          err?.message ??
            err?.messages ??
            err?.data?.message ??
            err?.data?.messages ??
            "Change password failed"
        );
      });
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    form.resetFields();
    onCloseModal();
  };

  const handleCancel = () => {
    form.resetFields();
    onCloseModal();
  };

  return (
    <Modal
      title="パスワード変更"
      open={isModalOpen}
      onCancel={handleCancel}
      centered={true}
      footer={[
        <Button
          className="button-white"
          key="cancel"
          onClick={handleCancel}
          type="primary"
        >
          キャンセル
        </Button>,
        <Button
          className="button-white"
          key="link"
          type="primary"
          // loading={loading}
          htmlType="submit"
          onClick={() => form.submit()}
        >
          パスワード変更
        </Button>,
      ]}
    >
      <div className="change-password-container">
        {contextHolder}
        <div className="change-password-form-container">
          <Form
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="現在のパスワード"
              name="oldPass"
              rules={[
                {
                  required: true,
                  message: MESSAGES.MSG_E_003("現在のパスワード"),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="新しいパスワード"
              name="newPass"
              dependencies={["oldPass"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value) {
                      //Value blank
                      return Promise.reject(
                        new Error(MESSAGES.MSG_E_003("新しいパスワード"))
                      );
                    } else if (getFieldValue("oldPass") == value) {
                      return Promise.reject(new Error(MESSAGES.MSG_E_016));
                    } else if (getFieldValue("oldPass") != value) {
                      //New pass not same with old pass
                      if (value.length >= 12 && value.length <= 16) {
                        if (REGEX_PASSWORD.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(new Error(MESSAGES.MSG_E_005));
                        }
                      }
                      return Promise.reject(
                        new Error(MESSAGES.MSG_E_004(12, 16))
                      );
                    } else return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="新しいパスワード再確認"
              name="confirmPassword"
              dependencies={["newPass"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value) {
                      //Value blank
                      return Promise.reject(
                        new Error(MESSAGES.MSG_E_003("新しいパスワード再確認"))
                      );
                    } else if (getFieldValue("newPass") != value) {
                      return Promise.reject(new Error(MESSAGES.MSG_E_006));
                    } else if (getFieldValue("newPass") == value) {
                      //New pass not same with old pass
                      if (value.length >= 5 && value.length <= 16) {
                        if (REGEX_PASSWORD.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(new Error(MESSAGES.MSG_E_005));
                        }
                      }
                      return Promise.reject(
                        new Error(MESSAGES.MSG_E_004(12, 16))
                      );
                    } else return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
